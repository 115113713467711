import { ApolloExplorerPage } from '@backstage-community/plugin-apollo-explorer';
import { LighthousePage } from '@backstage-community/plugin-lighthouse';
import { createApp } from '@backstage/app-defaults';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { ApiHolder, useApi } from '@backstage/core-plugin-api';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage } from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { GraphqlPage, PolicyManagementComponent, ConnectorManagementComponent } from '@runway/plugin-graphql';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import { DefaultTechDocsHome, TechDocsIndexPage, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { ExpandableNavigation, LightBox } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { SettingsLayout, UserSettingsPage } from '@backstage/plugin-user-settings';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { ListItem, ListItemText, StylesProvider } from '@material-ui/core';
import {
  AAEmployeeInfoFieldExtension,
  AaEmployeeInformationFieldExtension,
  AppCodeSearchPickerFieldExtension,
  AppShortnamePickerFieldExtension,
  ChatButton,
  ClusterPickerFieldExtension,
  ComponentInformationExtension,
  CoverityIssueTypeExtension,
  DescriptionFieldExtension,
  DevGitHubLogin,
  GitHubUserIdFieldExtension,
  GithubRepoFieldExtension,
  GithubSecretsPickerFieldExtension,
  GithubTeamFieldExtension,
  InfrastructureSelectorExtension,
  LDAPGroupFieldExtension,
  MicrogatewayPickerExtension,
  PaasAdminItemsFieldExtension,
  PaasFieldExtension,
  PaasSpRgItemsFieldExtension,
  RunwayThemes,
  SecretVaultFormFieldExtension,
  SecretVaultNamespaceFormFieldExtension,
  ServerNamingFieldExtension,
  SquadPickerFieldExtension,
  VerticalFieldExtension,
  aaPingSSOAuthApiRef,
} from '@runway/devkit';
import { AccessControlPage } from '@runway/plugin-access-control';
import { AdoPage } from '@runway/plugin-access-control/';
import { ADOProjectCreation, ADOProjectCreationRequest } from '@runway/plugin-ado-project-creation-frontend';
import { ApigeeAnalyticsDevAppStats, ApigeeAnalyticsProxyStats, ApigeePage } from '@runway/plugin-apigee-ui';
import { ApmPluginPage } from '@runway/plugin-apm-runway-plugin-standalone';
import { AssetManagementPage } from '@runway/plugin-asset-management';
import { CostManagementApp, CostManagementAutoVMShutdown } from '@runway/plugin-cost-management';
import { DashPage, GithubCopilotReportPage, StatusPage } from '@runway/plugin-dash';
import { DiffPage } from '@runway/plugin-diff-tool';
import { EmftPage } from '@runway/plugin-emft-ui';
import { EmqPage } from '@runway/plugin-emq-ui';
import { EzurlshortenerPage } from '@runway/plugin-ezurlshortener';
import { DevOpsPage } from '@runway/plugin-devops';
import {
  CloudInfrastructurePage,
  CloudsmithPage,
  CreateRancherResource,
  InfrastructureApp,
  InfrastructureCreateActiveDirectoryGroup,
  InfrastructureResourceGroup,
  InfrastructureServicePrincipal,
  InfrastructureVMs,
  InfrastructureVmManagement,
  LinuxPasswordReset,
  ResizeRancherResource,
  ViewKubernetesNamespace,
  ViewRancherProjects,
} from '@runway/plugin-infrastructure';
import { OpshubPage, OpshubEventhubPage, OpshubAccessPage } from '@runway/plugin-opshub-runway-ui';
import {
  OrionAutoPage,
  OrionPage,
  PowerBIPage,
  ADBPage,
  AMLCIPage,
  AMLCCPage,
  AMLKVPage,
} from '@runway/plugin-orion-auto';
import { MailingListPage } from '@runway/plugin-kpaas-mailing-list';
import { MetricsPage } from '@runway/plugin-metrics';
import { MigrationGuidancePage } from '@runway/plugin-migration-guidance';
import { OrionPluginPage } from '@runway/plugin-orion-ui';
import { P42MainPage } from '@runway/plugin-project42-runway-network-lookup';
import { AmselfservicesMainPage } from '@runway/plugin-runway-iam-services';
import { SecretVaultPage } from '@runway/plugin-secret-vault';
import { SituationalAwarenessPage } from '@runway/plugin-situational-awareness';
import { SwaggerPage } from '@runway/plugin-swagger';
import { TowerPage } from '@runway/plugin-tower';
import { TowerApiPage } from '@runway/plugin-tower-api';
import { Tokens } from '@runway/plugin-user-setting-customizations';
import { UsermapPage } from '@runway/plugin-usermap-ui';
import React, { useEffect } from 'react';
import { Navigate, Route, useLocation } from 'react-router';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { oldBlueprintsPage } from './components/core/OldBlueprintsPage';
import { RunwaySignInPage } from './components/core/RunwaySignInPage';
import { ErrorPage } from './components/core/errorPage';
import { HomePage } from './components/home/HomePage';
import { TemplateCard } from './components/scaffolder/templateCard';
import { searchPage } from './components/search/SearchPage';
import { techDocsPage } from './components/techdocs/TechDocsPage';
import goldenJson from './golden.json';
import * as plugins from './plugins';
import { trackerApiRef } from './tracker';
import { RunwayIcon } from './assets/icons/Runway';
import { SlackIcon } from './assets/icons/Slack';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { SessionStorageLoader } from './components/storage/SessionStorageLoader';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { AponoPage } from '@apono-io/backstage-plugin-apono';

const DefaultNotFoundPage = () => <ErrorPage status="404" statusMessage="404 Not Found" />;
export const globalTemplateFilter = (entity: Entity, filterResult: boolean): boolean =>
  filterResult && !entity?.metadata?.tags?.includes('experimental');

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  themes: RunwayThemes,
  icons: {
    runway: RunwayIcon,
    slack: SlackIcon,
  },
  components: {
    NotFoundErrorPage: DefaultNotFoundPage,
    SignInPage: RunwaySignInPage,
  },
});

const Tracker = () => {
  const location = useLocation();
  const tracker = useApi(trackerApiRef);
  const syntheticsUserAgent = 'Mozilla/5.0 (X11; Linux x86_64)';
  useEffect(() => {
    if (!window.navigator.userAgent.startsWith(syntheticsUserAgent)) {
      tracker.trackUrl(window.location.href);
    }
  }, [location, tracker]);
  return null;
};

async function authCallback(options: { apiHolder: ApiHolder }): Promise<{ token: string }> {
  const sso = options.apiHolder.get<any>(aaPingSSOAuthApiRef);
  const token = await sso.getAccessToken();
  return { token: token };
}

// Sign out using Available Providers tab is broken https://github.com/backstage/backstage/issues/3768
// Users should instead use the signout on the General Tab in the mean time
const MyAuthProviders = (
  <ListItem>
    <ListItemText
      primary="Ping SSO and GitHub.com used for authentication."
      secondary='Sign out on the "GENERAL" tab by clicking the menu button on the "Profile" card.'
    />
  </ListItem>
);

const CssLocationWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  return (
    <div id="location-wrapper" data-pathname={location.pathname}>
      {children}
    </div>
  );
};

const routes = (
  <FlatRoutes>
    {/* <Route path="/" element={<WelcomePage />} /> */}
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage initiallySelectedFilter="all" />} />
    <Route path="/catalog/:namespace/:kind/:name" element={<CatalogEntityPage />}>
      {entityPage}
    </Route>
    <Route path="/catalog-import" element={<Navigate to="/import" />} />
    <Route
      path="/import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: ['component', 'domain', 'system', 'api', 'group'],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      <TechDocsAddons>
        <ExpandableNavigation />
        <Mermaid />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome initialFilter="all" />
    </Route>
    <Route
      path="/apollo-explorer"
      element={
        <ApolloExplorerPage
          endpoints={[
            {
              title: 'AA Enterprise Data',
              graphRef: 'aa-enterprise-graph@current',
              initialState: {
                displayOptions: {
                  docsPanelState: 'open',
                  showHeadersAndEnvVars: true,
                },
              },
              authCallback: authCallback,
            },
            {
              title: 'AA TechOps Data',
              graphRef: 'aa-data-graph@current',
              initialState: {
                displayOptions: {
                  docsPanelState: 'open',
                  showHeadersAndEnvVars: true,
                },
              },
              authCallback: authCallback,
            },
          ]}
        />
      }
    />
    <Route path="/docs/:namespace/:kind/:name/*" element={<TechDocsReaderPage />}>
      {techDocsPage}
      <TechDocsAddons>
        <ExpandableNavigation />
        <Mermaid />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/create/AAInternal">{oldBlueprintsPage}</Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          components={{ TemplateCardComponent: TemplateCard }}
          templateFilter={entity => globalTemplateFilter(entity, true)}
          groups={[
            {
              title: 'Certified',
              filter: entity => globalTemplateFilter(entity, goldenJson.includes(entity?.spec?.owner as string)),
            },
            {
              title: 'Services',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'service'),
            },
            {
              title: 'Websites',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'website'),
            },
            {
              title: 'Docker',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'docker'),
            },
            {
              title: 'Databases',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'database'),
            },
            {
              title: 'Documentation',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'documentation'),
            },
            {
              title: 'Security',
              filter: entity => globalTemplateFilter(entity, entity?.spec?.type === 'security'),
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <SquadPickerFieldExtension />
        <AppShortnamePickerFieldExtension />
        <ClusterPickerFieldExtension />
        <GithubSecretsPickerFieldExtension />
        <GithubRepoFieldExtension />
        <GithubTeamFieldExtension />
        <DescriptionFieldExtension />
        <AAEmployeeInfoFieldExtension />
        <AaEmployeeInformationFieldExtension />
        <GitHubUserIdFieldExtension />
        <VerticalFieldExtension />
        <LDAPGroupFieldExtension />
        <PaasAdminItemsFieldExtension />
        <PaasSpRgItemsFieldExtension />
        <PaasFieldExtension />
        <ServerNamingFieldExtension />
        <AppCodeSearchPickerFieldExtension />
        <SecretVaultFormFieldExtension />
        <SecretVaultNamespaceFormFieldExtension />
        <MicrogatewayPickerExtension />
        <InfrastructureSelectorExtension />
        <ComponentInformationExtension />
        <CoverityIssueTypeExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/dash" element={<DashPage />} />
    <Route path="/status" element={<StatusPage />} />
    <Route path="/infrastructure" element={<InfrastructureApp />} />
    <Route path="/cost-management" element={<CostManagementApp />} />
    <Route path="/cost-management/auto-vm-shutdown" element={<CostManagementAutoVMShutdown />} />
    <Route path="/infrastructure/service-principal" element={<InfrastructureServicePrincipal />} />
    <Route path="/infrastructure/resource-group" element={<InfrastructureResourceGroup />} />
    <Route path="/infrastructure/vm-management" element={<InfrastructureVmManagement />} />
    <Route path="/infrastructure/vms" element={<InfrastructureVMs />} />
    <Route path="/infrastructure/active-directory-group" element={<InfrastructureCreateActiveDirectoryGroup />} />
    <Route path="/infrastructure/rancher" element={<ViewRancherProjects />} />
    <Route path="/infrastructure/rancher/namespaces/:namespace" element={<ViewKubernetesNamespace />} />
    <Route path="/infrastructure/rancher/create" element={<CreateRancherResource />} />
    <Route
      path="/infrastructure/rancher/clusters/:cluster/projects/:project/namespaces/:namespace/limitsMemory/:limitsMemory/limitsCpu/:limitsCpu"
      element={<ResizeRancherResource />}
    />
    <Route path="/infrastructure/linux-password-reset" element={<LinuxPasswordReset />} />
    <Route path="/infrastructure/cloud" element={<CloudInfrastructurePage />} />
    <Route path="/cloudsmith" element={<CloudsmithPage />} />
    <Route path="/apigee" element={<ApigeePage />} />
    <Route path="/apigee/analytics/proxy-performance" element={<ApigeeAnalyticsProxyStats />} />
    <Route path="/apigee/analytics/devApp-performance" element={<ApigeeAnalyticsDevAppStats />} />
    <Route path="/access" element={<AccessControlPage />} />
    <Route
      path="/orion/self-service-batch-data-ingestion"
      element={<Navigate replace to="/dataanalytics/self-service-batch-data-ingestion" />}
    />
    <Route
      path="/orion/self-service-data-ingestion"
      element={<Navigate replace to="/dataanalytics/self-service-data-ingestion" />}
    />
    <Route path="/dataanalytics" element={<OrionAutoPage />} />
    <Route path="/orion" element={<Navigate replace to="/dataanalytics" />} />
    <Route path="/dataanalytics/powerbi" element={<PowerBIPage />} />
    <Route path="/orion/powerbi" element={<Navigate replace to="/dataanalytics/powerbi" />} />
    <Route path="/dataanalytics/orion-auto" element={<OrionPage />} />
    <Route path="/orion/orion-auto" element={<Navigate replace to="/dataanalytics/orion-auto" />} />
    <Route path="/dataanalytics/adb-access" element={<ADBPage />} />
    <Route path="/dataanalytics/aml-ci" element={<AMLCIPage />} />
    <Route path="/orion/aml-ci" element={<Navigate replace to="/dataanalytics/aml-ci" />} />
    <Route path="/dataanalytics/aml-cc" element={<AMLCCPage />} />
    <Route path="/dataanalytics/aml-kv" element={<AMLKVPage />} />
    <Route
      path="/orion/self-service-access-menu"
      element={<Navigate replace to="/dataanalytics/self-service-access-menu" />}
    />
    <Route path="/opshub" element={<OpshubPage />} />
    <Route path="/opshub/eventhub" element={<OpshubEventhubPage />} />
    <Route path="/opshub/access" element={<OpshubAccessPage />} />
    <Route path="/lighthouse/*" element={<LighthousePage />} />
    <Route path="/tower-api" element={<TowerApiPage />} />
    <Route path="/emft" element={<EmftPage />} />
    <Route path="/emq" element={<EmqPage />} />
    <Route path="/usermap" element={<UsermapPage />} />
    <Route path="/dev-github-login" element={<DevGitHubLogin />} />
    <Route path="/settings" element={<UserSettingsPage providerSettings={MyAuthProviders} />}>
      <SettingsLayout.Route path="/token" title="Tokens">
        <Tokens />
      </SettingsLayout.Route>
    </Route>
    <Route path="/situational-awareness" element={<SituationalAwarenessPage />} />
    <Route path="/tower" element={<TowerPage />} />
    {/* Leaving the old /observe route here in case customers have it bookmarked */}
    <Route path="/observe" element={<MetricsPage />} />
    <Route path="/metrics" element={<MetricsPage />} />
    <Route path="/migration-guidance" element={<MigrationGuidancePage />} />
    <Route path="/ba-appcode-register" element={<OrionPluginPage />} />
    <Route path="/ezurlshortener" element={<EzurlshortenerPage />} />
    <Route path="/project42" element={<P42MainPage />} />
    <Route path="/swagger" element={<SwaggerPage />} />
    <Route path="/asset-management" element={<AssetManagementPage />} />
    <Route path="/ado-project-creation" element={<ADOProjectCreation />} />
    <Route path="/ado-project-creation-request" element={<ADOProjectCreationRequest />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/access/ado" element={<AdoPage />} />
    <Route path="/apm" element={<ApmPluginPage />} />
    <Route path="/am-self-services" element={<AmselfservicesMainPage />} />
    <Route path="/kpaas-mailing-list" element={<MailingListPage />} />
    <Route path="/secret-vault" element={<SecretVaultPage />} />
    <Route path="/diff-tool" element={<DiffPage />} />
    <Route path="/graphql" element={<GraphqlPage />} />
    <Route path="/graphql/policy-management" element={<PolicyManagementComponent />} />
    <Route path="/graphql/connectors" element={<ConnectorManagementComponent />} />
    <Route path="/github-copilot-report" element={<GithubCopilotReportPage />} />
    <Route path="/devops" element={<DevOpsPage />} />
    <Route path="/unprocessed-entities" element={<CatalogUnprocessedEntitiesPage />} />;
    <Route path="/apono" element={<AponoPage />} />
  </FlatRoutes>
);

const App = app.createRoot(
  <StylesProvider injectFirst>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Tracker />
      <Root>
        <SessionStorageLoader />
        <CssLocationWrapper>{routes}</CssLocationWrapper>
        <ChatButton />
      </Root>
    </AppRouter>
  </StylesProvider>,
);

export default App;
