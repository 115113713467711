import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { RestClientApi, RestClientResponse } from '@runway/devkit-common';
import { Endpoints } from '@octokit/types';

type CreateInvitationResponse = Endpoints['POST /orgs/{org}/invitations']['response']['data'];

export interface GithubEnterpriseAccessServiceApi {
  addUserToGithubEnterprise: (email: string) => Promise<RestClientResponse<CreateInvitationResponse>>;
}

export const GithubEnterpriseAccessApiRef = createApiRef<GithubEnterpriseAccessServiceApi>({
  id: 'plugin.create-project.ghec.service',
});

export default class GithubEnterpriseAccessService implements GithubEnterpriseAccessServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  async addUserToGithubEnterprise(email: string): Promise<RestClientResponse<CreateInvitationResponse>> {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.post<CreateInvitationResponse>(
      `${this.backendUrl}/api/github/enterprise/invite`,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (res.status !== 200) {
      throw new Error('Failed to add user');
    }

    return res;
  }
}
