import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { RestClientApi } from '@runway/devkit-common';

export interface CopilotDashboardServiceApi {
  getCopilotUsage(): Promise<any>;
  getCopilotBilling(): Promise<any>;
  getCopilotBillingSeats(): Promise<any>;
  getGitHubCopilotTeamStats(): Promise<any>;
}

export const dashServiceApiRef = createApiRef<CopilotDashboardServiceApi>({
  id: 'plugin.dash.service',
});

export default class CopilotDashboardService implements CopilotDashboardServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  getCopilotUsage = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get(`${this.backendUrl}/api/github/copilot/metrics`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get data');
    }

    return res.data;
  };

  getCopilotBilling = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get(`${this.backendUrl}/api/dash/getCopilotBilling`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get data');
    }
    return res.data;
  };

  getCopilotBillingSeats = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get(`${this.backendUrl}/api/dash/getCopilotBillingSeats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get data');
    }
    return res.data;
  };

  getGitHubCopilotTeamStats = async () => {
    const { token } = await this.identityApi.getCredentials();

    try {
      const response = await this.restClient.get(`${this.backendUrl}/api/github/copilot/stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: status => status === 200,
      });

      if (!response?.data?.verticals) {
        return { verticals: [] };
      }

      return response.data.verticals;
    } catch (error) {
      const message = this.restClient.isRestError(error)
        ? `Server returned ${error.response?.status}: ${error.response?.statusText}`
        : `Failed to get Copilot user metrics: ${(error as Error).message}`;
      throw new Error(message);
    }
  };
}
