import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { RestClientApi } from '@runway/devkit-common';

export type GithubCopilotAccessResponse = {
  url: string;
  role: 'member' | 'maintainer';
  state: 'active' | 'pending';
};

export interface GithubCopilotAccessServiceApi {
  getGithubCopilotUserCount(): Promise<number>;
  addUserToGithubCopilot(username: string): Promise<GithubCopilotAccessResponse>;
}

export const GithubCopilotAccessApiRef = createApiRef<GithubCopilotAccessServiceApi>({
  id: 'plugin.github',
});

export default class GithubCopilotAccessService implements GithubCopilotAccessServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  getGithubCopilotUserCount = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get(`${this.backendUrl}/api/github/copilot/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get maximun users');
    }
    return res.data.githubCopilotUserCount;
  };

  addUserToGithubCopilot = async (username: string) => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.post(
      `${this.backendUrl}/api/github/copilot/users`,
      {
        username,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Failed to add user');
    }

    return res.data;
  };
}
