import axios, { AxiosResponse } from 'axios';

export enum BucketId {
  PAGE_VIEW = 'page-view',
  DATA_LAKE_BUCKET = 'rw-dev-user-obs-bucket-self-service-data-lake-bucket',
  ORION_DATALAKE_ACCESS = 'rw-dev-user-obs-bucket-orion-datalake-access-bucket',
  USE_METRIC = 'rw-dev-user-obs-bucket-use-metric',
  IMPORT_METRIC = 'rw-dev-user-obs-bucket-import-metric',
  TEMPLATE_METRIC = 'rw-dev-user-obs-bucket-template-metric',
}

export enum ClientId {
  RUNWAY_FRONTEND = 'runway-frontend',
  ORION_DATALAKE_ACCESS = 'orion-datalake-access',
  RUNWAY_GENERIC = 'runway-generic',
}

export const PluginId = {
  VM_MANAGEMENT_REBOOT_SERVER: { id: 'vm-management-reboot-server', name: 'VM Management Reboot Server' },
  VM_MANAGEMENT_PATCH_SERVER: { id: 'vm-management-patch-server', name: 'VM Management Patch Server' },
  VM_MANAGEMENT_PATCH_STATUS: { id: 'vm-management-patch-status', name: 'VM Management Patch Status' },
  TEMPLATE_CREATE_GITHUB_TEAM: { id: 'template-create-github-team', name: 'Create Github Team via Template' },
  TEMPLATE_CREATE_RANCHER_NAMESPACE: {
    id: 'template-create-rancher-namespace',
    name: 'Create Rancher Namespace via Template',
  },
  ACCESS_CONTROL_SURVEYS_ACCESS: { id: 'access-control-surveys-access-page', name: 'Surveys Access' },
  TEMPLATE_CREATE_AAD_GROUP: { id: 'template-create-aad-group', name: 'Create AAD Group via Template' },
  ACTIVE_DIRECTORY_GROUP: { id: 'active-directory-group', name: 'Active Directory Group Request' },
  MANAGE_RANCHER_ADD_NAMESPACE: { id: 'manage-rancher-add-namespace', name: 'Create Rancher Project and Namespace' },
  INFRASTRUCTURE_RESOURCE_GROUP: { id: 'infrastructure-resource-group', name: 'Create Resource Group' },
  INFRASTRUCTURE_SERVICE_PRINCIPAL: { id: 'infrastructure-create-service-principal', name: 'Create Service Principal' },
  ADO_PROJECT_ACCESS: { id: 'access-control-adoc-project-access', name: 'ADO Project Access' },
  ADO_LICENSE_ACCESS: { id: 'access-control-adoc-license', name: 'ADO License Request' },
  CLOUD_ELEVATED_ACCESS: { id: 'access-control-cloud-elevated-access', name: 'Cloud Elevated Access' },
  ENTERPRISE_TOOLS: { id: 'access-control-enterprise-tools', name: 'Enterprise Tools' },
  GHEC_ACCESS: { id: 'access-control-ghec-invite-form', name: 'GHEC Access' },
  GHEC_REPO_ADMINS: { id: 'ghec-repo-admins', name: 'GHEC Repo Admins' },
  PFADMIN_REQUEST: { id: 'plugin-access-control-pfadmin-page', name: 'Pingfederate Request' },
  SURVEY_ACCESS: { id: 'access-control-surveys-access-page', name: 'Survey Access' },
  VDI_DESKTOP_ACCESS: { id: 'access-control-vdi-desktop', name: 'VDI Desktop Access' },
  ORION_DATA_LAKE_ACCESS: { id: 'access-control-orion-data-lake', name: 'Orion Data Lake Access' },
  LINUX_PASSWORD_RESET: { id: 'infrastructure-linux-password-reset', name: 'Linux Password Reset' },
  RESIZE_RANCHER_PROJECT_NAMESPACE: {
    id: 'manage-rancher-resize-existing',
    name: 'Resize Rancher Project and Namespace',
  },
  DELETE_SERVICE_PRINCIPAL: { id: 'infrastructure-delete-SP', name: 'Delete Service Principal' },
  CREATE_VM_SNAPSHOT: { id: 'create-vm-snapshot', name: 'Create VM Snapshot' },
  DECOMM_VM: { id: 'decommision-vm', name: 'Decommision VM' },
  FIND_MY_SERVER: { id: 'vm-management-find-my-server', name: 'Find My Server' },
  EXTEND_FILE_SYSTEM: { id: 'VM_ExtendFileSystem', name: 'Extend File System' },
  VM_BUILD: { id: 'infrastructure-vm-build', name: 'VM Build' },
  ORION_DATA_LAKE_PROVISIONING: { id: 'orion-datalake-provisioning', name: 'Orion Data Lake Provisioning' },
  ORION_POWER_BI_LICENSE: { id: 'orion-auto-power-bi-pro-license', name: 'Orion PowerBI Pro License' },
  XMATTERS_ONBOARDING: { id: 'xmatters-onboarding', name: 'XMatters Onboarding' },
  TOWER_DEPLOYMENT: { id: 'tower-deployment', name: 'Tower Deployment' },
  TOWER_OVERRIDE: { id: 'tower-override', name: 'Tower Override' },
  TOWER_OVERRIDE_REPORT: { id: 'tower-override-report', name: 'Tower Override Report' },
  USERMAP: { id: 'usermap', name: 'Usermap' },
  EMFT: { id: 'emft-ui', name: 'EMFT Manage Jobs' },
  EMFT_SEARCH: { id: 'emft-search-search', name: 'EMFT Search Jobs' },
  EMFT_ASSOCIATE: { id: 'emft-search-associate', name: 'EMFT Associate Jobs' },
  APIGEE_MANAGE_PROXY: { id: 'apigee-manage-proxy', name: 'Apigee Manage Proxy' },
  APIGEE_ANALYTICS: { id: 'apigee-analytics', name: 'Apigee Analytics' },
  APIGEE_APP_APPROVAL: { id: 'apigee-app-approval', name: 'Apigee App Approval' },
  APIGEE_API_BLUEPRINT: { id: 'apigee-api-blueprint', name: 'Apigee API Blueprint' },
  CATALOG_DELETE: { id: 'catalog-delete', name: 'Catalog Delete' },
  ARGO_DELETE: { id: 'argo-delete', name: 'Argo Delete' },
  ARGO_UPDATE: { id: 'argo-update', name: 'Argo Update' },
  CATALOG_SYNC_ALL_DEPLOYMENTS: { id: 'catalog-sync-all-deployments', name: 'Catalog Sync All Deployments' },
  CATALOG_DEPLOY_ARGO_APP: { id: 'catalog-deploy-argo-app', name: 'Catalog Deploy Argo App' },
  BA_SUBSCRIPTION_APPCODE_REGISTRATION: {
    id: 'ba-subscription-appcode-registration',
    name: 'BA Subscription AppCode Registration',
  },
  DEVOPS_PLUGIN: { id: 'devops-runway-plugin', name: 'DevOps Plugin' },
  SWAGGER_VALIDATE: { id: 'swagger-validate', name: 'Swagger Spec Validate' },
  GITHUB_COPILOT: { id: 'github-copilot-access', name: 'GitHub Copilot Access' },
};

export type ObserveRecord = {
  bucket_id: BucketId;
  client_id: ClientId;
  actor?: string;
  status?: string;
  data: any;
};

type PluginPageViewResponse = {
  error: undefined | string;
  records: {
    pluginName: string;
    data: {
      id: string;
      client_id: ClientId;
      created_at: Date;
    }[];
  }[];
};

export type PluginRecordResponse = {
  records: {
    id: string;
    bucket_id: string;
    client_id: string;
    data: any;
    created_at: Date | string;
  }[];
  error: undefined | unknown;
};

export type ItemsResponse = {
  id: string;
  bucket_id: string;
  client_id: string;
  data: PageViewDataResponse;
  created_at: Date;
};

type PageViewDataResponse = {
  url: string;
  referrer: string;
  user_agent: string;
  user_id: string;
};

export type PageViewSummaryByMonthResponse = {
  records: {
    date: string;
    plugin: number;
    import: number;
    create: number;
    total: number;
  }[];
  error: undefined | unknown;
};

export type MetricsCountByMonthResponse = {
  records: {
    date: string;
    useMetric: number;
    templateMetric: number;
    importMetric: number;
  }[];
  error: undefined | unknown;
};

export type GenericUseCountByMonthResponse = {
  records: {
    date: string;
    count: number;
  }[];
  error: undefined | unknown;
};

export type PluginListResponse = {
  pluginList: {
    name: string;
    id: string;
  }[];
};

export type PluginCountTotalResponse = {
  count: number;
};

export interface ObserveServiceApi {
  createRecord: (record: ObserveRecord) => Promise<AxiosResponse>;
  getObservePageViewCountByMonth: () => Promise<PageViewSummaryByMonthResponse>;
  getObservePageViewRecordsByPlugin: () => Promise<PluginPageViewResponse>;
  getObserveMetricsCountByMonth: () => Promise<MetricsCountByMonthResponse>;
  getObservePluginUseMetricsByMonth: (pluginId: string) => Promise<GenericUseCountByMonthResponse>;
  getObservePluginCountTotal: (pluginId: string) => Promise<PluginCountTotalResponse>;
  getObserveTemplateUseMetricsByMonth: (templateName: string) => Promise<GenericUseCountByMonthResponse>;
  getObservePluginList: () => Promise<PluginListResponse>;
  getObservePluginRecords: (pluginId: string, page: number, limit: number) => Promise<PluginRecordResponse>;
}

export class ObserveService implements ObserveServiceApi {
  constructor(
    private backendUrl: string,
    private token: string,
  ) {}
  getToken = async () => this.token;
  createRecord = async (record: ObserveRecord): Promise<AxiosResponse> => {
    const token = await this.getToken();
    return axios.post(`${this.backendUrl}/api/observe/records`, record, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getObservePageViewCountByMonth = async (): Promise<PageViewSummaryByMonthResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pageViewCountByMonth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  };

  getObservePageViewRecordsByPlugin = async (): Promise<PluginPageViewResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pageViewRecordsByPlugin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  };

  getObserveMetricsCountByMonth = async (): Promise<MetricsCountByMonthResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/metricsCountByMonth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  };

  getObservePluginUseMetricsByMonth = async (pluginId: string): Promise<GenericUseCountByMonthResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pluginUseCountByMonth?pluginId=${pluginId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  };

  getObservePluginCountTotal = async (pluginId: string): Promise<PluginCountTotalResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pluginCountTotal`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pluginId,
      },
    });
    return resp.data;
  };

  getObserveTemplateUseMetricsByMonth = async (templateName: string): Promise<GenericUseCountByMonthResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(
      `${this.backendUrl}/api/observe/records/templateUseCountByMonth?templateName=${templateName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return resp.data;
  };

  getObservePluginList = async (): Promise<PluginListResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pluginList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  };

  getObservePluginRecords = async (pluginId: string, page: number, limit: number): Promise<PluginRecordResponse> => {
    const token = await this.getToken();
    const resp = await axios.get(`${this.backendUrl}/api/observe/records/pluginRecords`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        pluginId,
        page,
        limit,
      },
    });
    return resp.data;
  };
}
