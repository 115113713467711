import { InfoCard } from '@backstage/core-components';
import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { useState } from 'react';
import { DiffComponent } from '../DiffComponent/DiffComponent';

const taxiwayVersions = ['v4.2.10', 'v5.9.2', 'v6.1.7'];

const DEFAULT_REPO_HELPER_TEXT = 'Enter the name of the repo you want to compare';

export const ContentComponent = () => {
  const [repo, setRepo] = useState<string>('');
  const [taxiwayRef, setTaxiwayRef] = useState<string>(taxiwayVersions[0]);
  const [repoError, setRepoError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleRepoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setRepo(event.target.value);
    setRepoError('');
  };

  React.useEffect(() => {
    if (repo && repo.length > 40) {
      setRepoError('Repo name must be lower than 40 characters');
    }
  }, [repo]);

  const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    setTaxiwayRef(event.target.value);
    setRepoError('');
  };

  const handleSubmit = () => {
    if (!repo) {
      setRepoError('Please provide a repo name');
      return;
    }

    setRepoError('');
    setSubmitted(true);
  };

  return (
    <>
      <InfoCard title="Taxiway CI/CD diff tool">
        <Typography variant="body1">
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item sm={2}>
              <TextField
                fullWidth
                id="Repo Name"
                label="Placeholder: Runway"
                aria-label="repoNameInput"
                variant="outlined"
                value={repo}
                onChange={handleRepoChange}
                error={!!repoError}
                helperText={repoError ? repoError : DEFAULT_REPO_HELPER_TEXT}
              />
            </Grid>
            <Grid item sm={1}>
              <Box>
                <FontAwesomeIcon size="2x" icon={faCodeCompare} />
              </Box>
            </Grid>
            <Grid item sm={2}>
              <FormControl>
                <FormLabel aria-label="taxiwayVersion">Taxiway Version (latest)</FormLabel>
                <RadioGroup row aria-labelledby="taxiwayVersion" onChange={handleTagChange} value={taxiwayRef}>
                  <FormControlLabel color="primary" value={taxiwayVersions[0]} control={<Radio />} label="v4" />
                  <FormControlLabel value={taxiwayVersions[1]} control={<Radio />} label="v5" />
                  <FormControlLabel value={taxiwayVersions[2]} control={<Radio />} label="v6" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item sm={2}>
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Show Me What's New
              </Button>
            </Grid>
          </Grid>
        </Typography>
        <Grid item>{submitted && <DiffComponent repo={repo} taxiwayVersion={taxiwayRef} />}</Grid>
      </InfoCard>
    </>
  );
};
