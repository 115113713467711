import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Content, Page } from '@backstage/core-components';
import { HeroHeader } from '@runway/devkit';
import { HomePageCard } from './HomePageCards';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import '@runway/devkit/src/components/ChatButton/ChatButton.css';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  announcementWrapper: {
    marginBottom: 20,
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0 40px',
  },
  newAnnouncements: {
    paddingBottom: '10px',
  },
});

export const HomePage = () => {
  const config = useApi(configApiRef);
  const baseUrl = config.getString('app.baseUrl');
  const classes = useStyles();
  return (
    <Page themeId="home">
      <HeroHeader
        title="Welcome to Runway"
        subtitle="The developer experience platform providing a hub to identify, discover, and create American Airlines technology"
      />
      <Content className={classes.content}>
        <div>
          <div className={classes.newAnnouncements}>
            <NewAnnouncementBanner />
          </div>
          <Typography variant="h5" align="center">
            Getting Started
          </Typography>
          <div className={classes.contentRow}>
            <HomePageCard
              image="undraw_create.svg"
              href={`${baseUrl}/create`}
              title="Create an app"
              description="Get started quickly with our many templates"
            />
            <HomePageCard
              image="undraw_transfer_files.svg"
              href={`${baseUrl}/migration-guidance`}
              title="Migrate an existing application"
              description="Guidance on migrating your application to Runway Shared Clusters"
            />
            <HomePageCard
              image="undraw_security.svg"
              href={`${baseUrl}/access`}
              title="Get access"
              description="Self service access control for GitHub and more"
            />
            <HomePageCard
              image="undraw_new_ideas.svg"
              href={`${baseUrl}/announcements`}
              title="View announcements"
              description="View Runways new features and announcements"
            />
          </div>
        </div>
        <div>
          <Typography variant="h5" align="center">
            Need Help?
          </Typography>
          <div className={classes.contentRow}>
            <HomePageCard
              image="undraw_cloud_docs.svg"
              href={`${baseUrl}/docs/default/component/runway`}
              title="Runway Docs"
              description="Read the latest info about Runway"
            />
            <HomePageCard
              image="undraw_features.svg"
              href="https://github.com/AAInternal/runway/issues/new?template=feature_template.md"
              title="Request a feature"
              description="Is Runway missing a vital feature? Let us know!"
              target="_blank"
            />
            <HomePageCard
              image="undraw_social.svg"
              href="https://americanairlines.slack.com/archives/C0112F1JKNG"
              title="Chat with us"
              description="Join our Slack channel to stay up to date and ask questions"
              target="_blank"
            />
          </div>
        </div>
      </Content>
    </Page>
  );
};
