import React, { useState } from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import axios from 'axios';
import { useAsync } from 'react-use';
import { FieldValidation } from '@rjsf/utils';
import { Squad360ServiceApiRef, aaPingSSOAuthApiRef } from '../../../services';

type ProjectAndNamepsace = {
  name: string;
  namespace: string;
};

type ClusterNamesResponse = {
  data: ProjectAndNamepsace[];
};

const filterNamespaces = async (projectsAndNamespaces: ProjectAndNamepsace[], ownSupportedApps: any[]) => {
  const appShortNames = ownSupportedApps.map((archerApp: any) => archerApp?.shortName.toLowerCase()).filter(Boolean);
  const returnedNamespaces = projectsAndNamespaces
    .filter(object =>
      appShortNames.some(appShortName => object.namespace.includes(appShortName) || object.name.includes(appShortName)),
    )
    .map(object => object.namespace);

  return returnedNamespaces;
};

export const Namespace = ({
  cluster,
  formData,
  onChange,
}: {
  cluster: string;
  formData: string;
  onChange: (namespace: string) => void;
}) => {
  const ssoApi = useApi(aaPingSSOAuthApiRef);
  const squad360 = useApi(Squad360ServiceApiRef);
  const config = useApi(configApiRef);
  const [selectedNamespace, setSelectedNamespace] = useState<string>(formData);

  const getNamespaces = useAsync(async () => {
    if (!cluster) {
      return { availableNamespaces: [] };
    }
    try {
      const token = await ssoApi.getAccessToken();
      const resp: ClusterNamesResponse = await axios.get(
        `${config.getString('clusterService.baseUrl')}/projects?clusterNames=${cluster}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const projectsAndNamespaces: ProjectAndNamepsace[] = resp.data;
      const ownSupportedApps = await squad360.getOwnSupportedApps();
      if (projectsAndNamespaces.length > 0) {
        const filteredNamespacesResp = await filterNamespaces(projectsAndNamespaces, ownSupportedApps);
        return { availableNamespaces: filteredNamespacesResp };
      }
    } catch (error: any) {
      throw new Error('Cannot retrieve namespaces');
    }
    return { availableNamespaces: [] };
  }, [cluster]);

  return (
    <>
      <Autocomplete
        id="namespace"
        data-testid="namespace_selector"
        aria-label="namespace-dropdown"
        options={getNamespaces.value?.availableNamespaces || []}
        value={selectedNamespace}
        onChange={(_, namespace: string | null) => {
          setSelectedNamespace(namespace as string);
          onChange(namespace as string);
        }}
        getOptionLabel={option => option}
        getOptionSelected={option => !!option}
        style={{ width: '100%' }}
        renderInput={params => <TextField required {...params} label="Rancher Namespace" variant="outlined" />}
      />
      {getNamespaces.loading && (
        <LinearProgress
          style={{
            marginTop: 30,
            marginLeft: 10,
            marginRight: 10,
            width: '100%',
          }}
        />
      )}
      {getNamespaces.error && <Alert severity="error">Cannot get namespaces for cluster {cluster}</Alert>}
    </>
  );
};

export const namespaceValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please select a namespace');
  }
};
