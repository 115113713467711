import { Card, CardHeader, CardContent, Typography, ListItem, List } from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

type ChangeManagementApplicationEntity = {
  apiVersion: string;
  kind: string;
  metadata: {
    name: string;
  };
  spec: {
    leadership: {
      firstName: string;
      lastName: string;
      employeeID: number;
      role: 'Owner' | 'Managing Director' | 'Tech Lead' | 'Delegate';
    }[];
  };
};

export const ChangeManagementLeadershipCard = () => {
  const { entity } = useEntity<ChangeManagementApplicationEntity>();
  return (
    <Card>
      <CardHeader title="Leadership" />
      <CardContent>
        <List>
          {entity.spec.leadership.map(leader => (
            <ListItem>
              <Typography>
                {leader.role}: ({leader.employeeID}) {leader.firstName} {leader.lastName}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
