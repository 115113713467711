import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';

type ChangeManagementApplicationEntity = {
  apiVersion: string;
  kind: string;
  metadata: {
    name: string;
  };
  spec: {
    link: string;
    applicationName: string;
    applicationShortName: string;
    criticality: string;
    lifecycle: string;
    product: string;
    isPCI: boolean;
    isTSA: boolean;
    id: string;
  };
};

export const ChangeManagementApplicationInformationCard = () => {
  const { entity } = useEntity<ChangeManagementApplicationEntity>();
  return (
    <Card>
      <CardHeader title="Application Information" />
      <CardContent>
        <Typography>
          ShortName:{' '}
          <a href={entity.spec.link} target="_blank">
            {entity.spec.applicationShortName}
          </a>
        </Typography>
        <Typography>Criticality: {entity.spec.criticality}</Typography>
        <Typography>Archer ID: {entity.spec.id}</Typography>
        <Typography>Lifecycle: {entity.spec.lifecycle}</Typography>
        <Typography>Product: {entity.spec.product}</Typography>
        <Typography>PCI: {entity.spec.isPCI ? 'yes' : 'no'}</Typography>
        <Typography>TSA: {entity.spec.isTSA ? 'yes' : 'no'}</Typography>
      </CardContent>
    </Card>
  );
};
