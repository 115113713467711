import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';
import axios from 'axios';
import {
  ArgoCDServiceApi,
  createArgoAppReq,
  ArgoNameAndCluster,
  ArgoInstanceData,
  UpdateArgoReq,
} from './argocd.catalog.types';

export const ArgoCDServiceApiRef = createApiRef<ArgoCDServiceApi>({
  id: 'plugin.plugin-argocd-backend.service',
});

export class ArgoCDService implements ArgoCDServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private ssoApi: OAuth2AAPingSSOApi,
    private clusterUrl: string,
  ) {}

  async createArgoApp(req: createArgoAppReq) {
    const { token } = await this.identityApi.getCredentials();
    await axios.post(`${this.backendUrl}/api/argocd/createArgo`, req, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async syncArgoApp(appSelector: string) {
    const { token } = await this.identityApi.getCredentials();
    const ArgoAppResp = await axios.post(
      `${this.backendUrl}/api/argocd/sync`,
      {
        appSelector: appSelector,
        terminateOperation: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return ArgoAppResp.data;
  }

  getArgoNameAndCluster = async (argoSelector: string): Promise<ArgoNameAndCluster[]> => {
    if (!argoSelector) {
      throw new Error('Annotation missing (argocd/app-name)');
    }
    try {
      const { token } = await this.identityApi.getCredentials();
      const res = await axios.get(`${this.backendUrl}/api/argocd/find/selector/${argoSelector}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data;
    } catch (e) {
      throw new Error('Error Getting ArgoCD Name and Cluster');
    }
  };

  getArgoInstanceData = async (
    clusterName: string,
    argoSelector: string,
    appName: string,
  ): Promise<ArgoInstanceData> => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(
      `${this.backendUrl}/api/argocd/argoInstance/${clusterName}/applications/selector/${argoSelector}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!res.data.items?.length) {
      throw new Error('Unexpected Response Body Getting Argo Instance Data');
    }
    const instanceData = res.data.items.find(
      (appInstance: { metadata: { name: string } }) => appInstance.metadata.name === appName,
    );
    if (!instanceData)
      throw new Error(
        `Argo app ${appName} and corresponding deployment information not found. Please try again. If the problem persists, reach out to #Runway on Slack`,
      );
    return instanceData;
  };

  updateArgoApp = async (req: UpdateArgoReq) => {
    const { token } = await this.identityApi.getCredentials();

    await axios.put(`${this.backendUrl}/api/argocd/updateArgo/${req.appName}`, req, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  deleteArgoApplication = async (clusterName: string, appName: string) => {
    const token = await this.ssoApi.getAccessToken();

    let rancherEnv = '';
    if (clusterName.includes('-np-')) rancherEnv = 'nonprod';
    if (clusterName.includes('-lab-')) rancherEnv = 'lab';
    if (clusterName.includes('-p-')) rancherEnv = 'prod';
    const clusters = await axios.get<{ name: string; cluster_id: string }[]>(
      `${this.clusterUrl}/v2/${rancherEnv}/clusters/`,
      { headers: { authorization: `Bearer ${token}` } },
    );

    const clusterInfo = clusters.data.find(cluster => cluster.name === clusterName);

    if (clusterInfo) {
      const appDeleteResponse = await axios.delete<null>(
        `${this.clusterUrl}/v2/${rancherEnv}/clusters/${clusterInfo.cluster_id}/deployments/applications/${appName}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      return appDeleteResponse.data;
    }

    throw new Error('Error Deleting Argo Application');
  };

  deleteArgoProject = async (clusterName: string, argoAppName: string) => {
    const token = await this.ssoApi.getAccessToken();

    let rancherEnv = '';
    if (clusterName.includes('-np-')) rancherEnv = 'nonprod';
    if (clusterName.includes('-lab-')) rancherEnv = 'lab';
    if (clusterName.includes('-p-')) rancherEnv = 'prod';
    const clusters = await axios.get<{ name: string; cluster_id: string }[]>(
      `${this.clusterUrl}/v2/${rancherEnv}/clusters/`,
      { headers: { authorization: `Bearer ${token}` } },
    );

    const clusterInfo = clusters.data.find(cluster => cluster.name === clusterName);

    if (clusterInfo) {
      const projectDeleteResponse = await axios.delete<null>(
        `${this.clusterUrl}/v2/${rancherEnv}/clusters/${clusterInfo.cluster_id}/deployments/projects/${argoAppName}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      return projectDeleteResponse.data;
    }

    throw new Error('Error Deleting Argo Project');
  };
}
