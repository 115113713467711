import { createApiRef, OAuthApi, BackstageIdentityApi } from '@backstage/core-plugin-api';
import { Octokit } from '@octokit/rest';

const owner = 'AAInternal';
const repoName = 'graphql-policies';
const repoConnectorName = 'apollo-connectors';

export type RepoDetails = {
  name?: any;
  repoSpec?: any;
  collaborators?: any;
};

export interface GraphqlProjectListQueryApi {
  requestPolicy: (formData: {
    selectedRows: any;
    clientId: string;
    archerShortName: string;
    userEmail: string;
    selectedPolicyNames: string[];
    env: string;
    githubTeam: string;
  }) => Promise<any>;
  getEnvDataJsonFile: (env: string) => Promise<any>;
  createPolicy: (formData: {
    policyName: string;
    shortName: string;
    description: string;
    email: string;
    githubName: string;
    env: string;
  }) => Promise<any>;
  getConnectorEnvDataJsonFile: (env: string) => Promise<any>;
  registerConnector: (formData: {
    connectorName: string;
    shortName: string;
    description: string;
    email: string;
    githubName: string;
    vaultNamespace: string;
    vaultSecretPath: string;
    authProvider: string;
    authURL: string;
    env: string;
  }) => Promise<any>;
}

export const GraphqlProjectListQueryApiRef = createApiRef<GraphqlProjectListQueryApi>({
  id: 'plugin.graphql.graphql.service',
});

export class GraphqlProjectsList implements GraphqlProjectListQueryApi {
  constructor(private ghecAuthApi: OAuthApi & BackstageIdentityApi) {}

  getAuthApi() {
    return this.ghecAuthApi;
  }

  parseTeam(githubName: string): string {
    return githubName.split('/').pop() || '';
  }

  getEnvDataJsonFile = async (env: string) => {
    const token = await this.getAuthApi().getAccessToken();
    const octokit = new Octokit({ auth: token });

    const response = await octokit.repos.getContent({
      owner,
      repo: repoName,
      path: `root/authz/data.json`,
      ref: env,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch data.json from branch ${env}: ${response.status}`);
    }

    if (!('content' in response.data)) {
      throw new Error(`Failed to fetch data.json from data structure: ${response.data}`);
    }

    const content = Buffer.from(response.data.content, 'base64').toString();
    return JSON.parse(content);
  };

  requestPolicy = async (formData: {
    selectedRows: any;
    clientId: string;
    archerShortName: string;
    userEmail: string;
    selectedPolicyNames: string[];
    env: string;
    githubTeam: string;
  }) => {
    const token = await this.getAuthApi().getAccessToken();
    const octokit = new Octokit({ auth: token });
    const currentDate = new Date();
    const timestamp = currentDate.toISOString().replace(/[-:.TZ]/g, '');
    let pullRequestUrl = '';

    try {
      for (const row of formData.selectedPolicyNames) {
        const newBranchName = `update-${row}-${timestamp}`;

        const { data: baseRef } = await octokit.git.getRef({
          owner,
          repo: repoName,
          ref: `heads/${formData.env}`,
        });

        await octokit.git.createRef({
          owner,
          repo: repoName,
          ref: `refs/heads/${newBranchName}`,
          sha: baseRef.object.sha,
        });

        const parsedTeam = this.parseTeam(formData.githubTeam);

        const newClientEntry = {
          clientid: formData.clientId,
          owner: `@${owner}/${parsedTeam}`,
          shortname: formData.archerShortName,
          email: formData.userEmail,
        };

        const filePath = `${row}/clients.json`;

        const { data: file } = await octokit.repos.getContent({
          owner,
          repo: repoName,
          path: filePath,
          ref: newBranchName,
        });

        if (!('content' in file)) {
          throw new Error(`Failed to fetch data.json from data structure: ${file}`);
        }

        const content = Buffer.from(file.content, 'base64').toString();

        const clients = JSON.parse(content);

        clients[row].clients.push(newClientEntry);

        const updatedJsonString = JSON.stringify(clients, null, 2);

        await octokit.repos.createOrUpdateFileContents({
          owner,
          repo: repoName,
          path: filePath,
          message: `Updated clients.json for policy: ${row}`,
          content: Buffer.from(updatedJsonString).toString('base64'),
          branch: newBranchName,
          sha: file.sha,
        });

        const { data: pr } = await octokit.pulls.create({
          owner: owner,
          repo: repoName,
          title: `fix: add new client to clients.json for ${row} policy`,
          head: newBranchName,
          base: formData.env,
        });

        pullRequestUrl = pr.html_url;
      }
    } catch (error) {
      const typedError = error as Error;

      return {
        success: false,
        error: typedError.message,
        pullRequestUrl: '',
      };
    }

    return {
      success: true,
      error: '',
      pullRequestUrl: pullRequestUrl,
    };
  };

  createPolicy = async (formData: {
    policyName: string;
    shortName: string;
    description: string;
    email: string;
    githubName: string;
    env: string;
  }) => {
    const token = await this.getAuthApi().getAccessToken();
    const octokit = new Octokit({ auth: token });

    const currentDate = new Date();
    const timestamp = currentDate.toISOString().replace(/[-:.TZ]/g, '');
    const branchName = `${formData.shortName}-${timestamp}`;
    let pullRequestUrl = '';

    try {
      const { data: refData } = await octokit.git.getRef({
        owner,
        repo: repoName,
        ref: `heads/${formData.env}`,
      });

      await octokit.git.createRef({
        owner,
        repo: repoName,
        ref: `refs/heads/${branchName}`,
        sha: refData.object.sha,
      });

      const { data: file } = await octokit.repos.getContent({
        owner,
        repo: repoName,
        path: '.github/CODEOWNERS',
        ref: branchName,
      });

      if (!('content' in file)) {
        throw new Error(`Failed to fetch data.json from data structure: ${file}`);
      }

      const parsedTeam = this.parseTeam(formData.githubName);

      let content = Buffer.from(file.content, 'base64').toString();

      content += `\n\n${formData.policyName}/ @AAInternal/${parsedTeam}`;

      await octokit.repos.createOrUpdateFileContents({
        owner,
        repo: repoName,
        path: '.github/CODEOWNERS',
        message: `Update CODEOWNERS with ${formData.policyName}`,
        content: Buffer.from(content).toString('base64'),
        branch: branchName,
        sha: file.sha,
      });

      const jsonContent = {
        [formData.policyName]: {
          clients: [],
          owner: `@${owner}/${parsedTeam}`,
          shortname: formData.shortName,
          email: formData.email,
          description: formData.description,
        },
      };

      await octokit.repos.createOrUpdateFileContents({
        owner,
        repo: repoName,
        path: `${formData.policyName}/clients.json`,
        message: `Create clients.json for ${formData.policyName}`,
        content: Buffer.from(JSON.stringify(jsonContent, null, 2)).toString('base64'),
        branch: branchName,
      });

      const { data: pr } = await octokit.pulls.create({
        owner: owner,
        repo: repoName,
        title: `fix: update CODEOWNERS and create policy dir with ${formData.policyName}`,
        head: branchName,
        base: formData.env,
      });

      pullRequestUrl = pr.html_url;

      await octokit.pulls.requestReviewers({
        owner: owner,
        repo: repoName,
        pull_number: pr.number,
        reviewers: ['@AAInternal/data-movement'],
      });
    } catch (error) {
      const typedError = error as Error;

      return {
        success: false,
        error: typedError.message,
        pullRequestUrl: '',
      };
    }

    return {
      success: true,
      error: '',
      pullRequestUrl: pullRequestUrl,
    };
  };

  getConnectorEnvDataJsonFile = async (env: string) => {
    const token = await this.getAuthApi().getAccessToken();
    const octokit = new Octokit({ auth: token });

    const response = await octokit.repos.getContent({
      owner,
      repo: repoConnectorName,
      path: `root/cnntr/data.json`,
      ref: env,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch data.json from branch ${env}: ${response.status}`);
    }

    if (!('content' in response.data)) {
      throw new Error(`Failed to fetch data.json from data structure: ${response.data}`);
    }

    const content = Buffer.from(response.data.content, 'base64').toString();
    return JSON.parse(content);
  };

  registerConnector = async (formData: {
    connectorName: string;
    shortName: string;
    description: string;
    email: string;
    githubName: string;
    vaultNamespace: string;
    vaultSecretPath: string;
    authProvider: string;
    authURL: string;
    env: string;
  }) => {
    const token = await this.getAuthApi().getAccessToken();
    const octokit = new Octokit({ auth: token });
    const currentDate = new Date();
    const timestamp = currentDate.toISOString().replace(/[-:.TZ]/g, '');
    const branchName = `${formData.shortName}-${timestamp}`;
    let pullRequestUrl = '';

    try {
      const { data: refData } = await octokit.git.getRef({
        owner,
        repo: repoConnectorName,
        ref: `heads/${formData.env}`,
      });

      await octokit.git.createRef({
        owner,
        repo: repoConnectorName,
        ref: `refs/heads/${branchName}`,
        sha: refData.object.sha,
      });

      const filePath = `${formData.connectorName}/config.json`;

      let file;
      try {
        const response = await octokit.repos.getContent({
          owner,
          repo: repoConnectorName,
          path: filePath,
          ref: branchName,
        });
        file = response.data;
      } catch (error) {
        if ((error as any).status === 404) {
          file = { content: Buffer.from('{}').toString('base64'), sha: undefined };
        } else {
          throw error;
        }
      }

      if (!('content' in file)) {
        throw new Error(`Failed to fetch config.json from data structure: ${file}`);
      }

      const parsedTeam = this.parseTeam(formData.githubName);

      const jsonContent = {
        [formData.connectorName]: {
          owner: `@${owner}/${parsedTeam}`,
          shortname: formData.shortName,
          email: formData.email,
          description: formData.description,
          vaultNamespace: `automation/${formData.vaultNamespace}`,
          vaultSecretPath: formData.vaultSecretPath,
          authProvider: formData.authProvider,
          authURL: formData.authURL,
        },
      };

      await octokit.repos.createOrUpdateFileContents({
        owner,
        repo: repoConnectorName,
        path: filePath,
        message: `Update config.json for ${formData.connectorName}`,
        content: Buffer.from(JSON.stringify(jsonContent, null, 2)).toString('base64'),
        branch: branchName,
        sha: file.sha,
      });

      const { data: pr } = await octokit.pulls.create({
        owner: owner,
        repo: repoConnectorName,
        title: `fix: register connector dir with ${formData.connectorName}`,
        head: branchName,
        base: formData.env,
      });

      pullRequestUrl = pr.html_url;

      await octokit.pulls.requestReviewers({
        owner: owner,
        repo: repoConnectorName,
        pull_number: pr.number,
        reviewers: ['@AAInternal/data-movement'],
      });
    } catch (error) {
      const typedError = error as Error;

      return {
        success: false,
        error: typedError.message,
        pullRequestUrl: '',
      };
    }

    return {
      success: true,
      error: '',
      pullRequestUrl: pullRequestUrl,
    };
  };
}
