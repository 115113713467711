import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Link } from '@material-ui/core';
import { Content, InfoCard } from '@backstage/core-components';
import { updateUserLicense } from '../../../services/adoaccess.service';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { useTrackedRequest } from '@runway/devkit';
import { useApi, configApiRef, identityApiRef, ProfileInfo } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import { useAsync } from 'react-use';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';

const licenseMap: any = { express: 'Basic', advanced: 'Basic + Test Plan' };

const LicenseSelection = () => {
  const configApi = useApi(configApiRef);
  const backendUrl = configApi.getString('backend.baseUrl');
  const identityApi = useApi(identityApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ADO_LICENSE_ACCESS.id,
  });

  const [license, setLicense] = useState('');
  const [ID, setID] = useState('');
  const [error, setError] = useState({ message: '' });
  const [radioError, setRadioError] = useState({ message: '' });
  const [alertStatus, setAlertStatus] = useState('');

  const { value: employeeId, error: aaIdError } = useAsync(async () => {
    try {
      const aaProfile = (await identityApi.getProfileInfo()) as ProfileInfo & { aaId: string };
      const aaId = aaProfile.aaId ?? '-1';
      if (aaId.length === 8) {
        return aaId.substring(2);
      }
      return aaId;
    } catch {
      return '-1';
    }
  }, [identityApi]);

  const handleLicenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicense(event.target.value);
    setAlertStatus('');
  };

  const handleIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setID(event.target.value);
    setAlertStatus('');
  };

  const resetErrors = () => {
    setError({ message: '' });
    setRadioError({ message: '' });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    resetErrors();

    if (license === '') {
      setRadioError(prev => ({ ...prev, message: 'Select the License Type' }));
      return;
    }

    if (ID.length !== 6 || isNaN(Number(ID)) || !!aaIdError) {
      setError(prev => ({ ...prev, message: 'ID must be 6 digits' }));
      return;
    }

    const { token } = await identityApi.getCredentials();
    try {
      await tracker(
        async () => {
          return await updateUserLicense(backendUrl, ID, employeeId!, license, token || '');
        },
        { licenseType: license, targetUserEmployeeId: ID, userEmployeeId: employeeId },
      );
      setAlertStatus('success');
    } catch (_: any) {
      setAlertStatus('error');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl error={!!radioError.message}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <FormLabel
              component="legend"
              style={{
                fontWeight: 'bold',
                color: 'hsl(210, 50%, 45%)',
                fontSize: 18,
                marginTop: 6,
              }}
            >
              Select License Type and enter employee ID of user needing to upgrade license
            </FormLabel>
            <RadioGroup aria-label="license" name="license" value={license} onChange={handleLicenseChange}>
              <FormControlLabel value="express" control={<Radio />} label="Basic ($6/month per user)" />
              <FormControlLabel value="advanced" control={<Radio />} label="Basic + Test Plan ($52/month per user)" />
            </RadioGroup>
            <FormHelperText>{radioError.message}</FormHelperText>
          </Grid>
          <Grid item>
            <TextField
              id="outlined-basic"
              label="AA ID ex:123456"
              variant="outlined"
              value={ID}
              onChange={handleIDChange}
              error={!!error.message}
              helperText={error.message}
            />
          </Grid>

          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              Submit License Request
            </Button>
          </Grid>
        </Grid>
        <br />
        {alertStatus === 'success' && (
          <Alert variant="outlined" severity="success">
            {ID} successfull granted a "{licenseMap[license]}" ADOC License!
          </Alert>
        )}
        {alertStatus === 'error' && (
          <Alert variant="outlined" severity="error">
            An error occured while upgrading your license.
          </Alert>
        )}
      </FormControl>
    </form>
  );
};

export const ADOLicenseContent = () => {
  return (
    <Content noPadding>
      <div style={{ maxWidth: 1080 }}>
        <InfoCard>
          <Typography variant="h6">Upgrade ADO License</Typography>
          <Typography component="div" variant="body1" style={{ margin: 12 }}>
            <p>
              All new users added to Azure DevOps (ADO) default with the free "Stakeholder" license. Users who want to
              leverage advanced features in ADO will need a paid license. You can review more details regarding ADOC
              Licenses and project access{' '}
              <Link
                href="https://developer.aa.com/docs/default/component/devops-tech-enablement/ado/AccessAndLicenses/"
                target="_blank"
              >
                here
              </Link>
              .
            </p>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 16,
                marginBottom: 2,
              }}
            >
              "Basic" License Functionality:
            </p>
            <p
              style={{
                marginTop: 0,
                marginLeft: 12,
              }}
            >
              <li>Delete Work Items</li>
              <li>Create "new" Tags on work items</li>
              <li>Code/git repos, pull requests, etc.</li>
              <li>Create/edit Shared Queries and query charts</li>
              <li>
                Advanced Backlog/Sprint tools (order/rank backlog, edit kanban settings, capacity, forecasting, etc.)
              </li>
              <li>Assign Work Items to Iterations</li>
              <li>Create/edit Delivery Plans (roadmap view)</li>
              <li>Create/edit Wiki pages</li>
              <li>Execute Test Cases in a Test Plan</li>
            </p>
            <p
              style={{
                fontWeight: 'bold',
                fontSize: 16,
                marginBottom: 2,
              }}
            >
              "Basic + Test Plan" License Functionality:
            </p>
            <p
              style={{
                marginTop: 0,
                marginLeft: 12,
              }}
            >
              <li>Everything the 'Basic' license provides</li>
              <li>Create/edit/delete Test Cases, Test Plans, Test Suites, and Test Configurations</li>
              <li>
                Due to the high cost, it is best to be granted sparingly for team members who need to manage the test
                plans and create test cases.
              </li>
            </p>
          </Typography>
          <LicenseSelection />
        </InfoCard>
      </div>
    </Content>
  );
};

export default ADOLicenseContent;
