import { Grid, FormControl } from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import FormContext from '../FormContext';
import { ObjectLike, HashiCorpVaultNamespacesProps } from '../types';
import { HashicorpVaultNamespaces } from '../../../components/HashiCorpVault';
import { componentGridStyle } from '../styles';

// Create a component that displays the HashiCorp Vault namespaces
export function FormHashiCorpVaultNamespaces<T extends ObjectLike>({
  name,
  size = 4,
  getShortName,
  restrictByAdmin = true,
}: HashiCorpVaultNamespacesProps<T>) {
  const { ctx } = useContext(FormContext);
  const { control } = ctx;
  const shortName = getShortName(useWatch({ control }));

  return (
    <Grid item xs={12} sm={12} md={size} style={componentGridStyle}>
      <FormControl aria-label="form-hashicorpvault-namespaces" variant="outlined" fullWidth required>
        <Controller
          name={name}
          control={ctx.control}
          render={({ field: { onChange } }) => (
            <HashicorpVaultNamespaces
              onChange={value => onChange(value.namespace)}
              shortName={shortName}
              restrictByAdmin={restrictByAdmin}
            />
          )}
        />
      </FormControl>
    </Grid>
  );
}
